$font-primary: 'Work Sans', Arial, sans-serif;
$font-secondary: 'Sacramento', Arial, serif;


// Overrides
$grid-gutter-width: 40px !default; 
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;

$brand-primary: #F14E95 !default;
$brand-secondary: #118DF0 !default; 

$brand-white: #fff;
$brand-black: #000;
$brand-darker: #444;
$brand-gray: #ccc;
$brand-lighter: #e9e9e9;
$brand-body-color: #818892;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #3b3d40;
$brand-bg-color: $brand-white;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom:       30px !default;



// Mixin
@mixin translateX($translatex) {
	-moz-transform: translateX($translatex);
	-webkit-transform: translateX($translatex);
	-ms-transform: translateX($translatex);
	-o-transform: translateX($translatex);
	transform: translateX($translatex);
}
@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition: 		all $transition ease;
    transition:         all $transition ease;
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin flex() {
	display: -webkit-box;      
  	display: -moz-box;         
  	display: -ms-flexbox;      
  	display: -webkit-flex;     
  	display: flex;             
}
@mixin flexwrap() {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap; 
	-moz-flex-wrap: wrap; 
}

@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?srf3rx');
	src:url('../fonts/icomoon/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
		url('../fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
		url('../fonts/icomoon/icomoon.svg?srf3rx#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin icomoon() {
	
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}

// Import 
@import 'bootstrap/mixins';
@import 'bootstrap/variables';




/* =======================================================
*
* 	Template Style 
*
* ======================================================= */

body {
	font-family: $font-primary;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.7;
	color: #828282;
	background: #fff;
}
#page {
	position: relative;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	@include transition(.5s);
	.offcanvas & {
		overflow: hidden;	
		position: absolute;
		
		&:after {
			@include transition(2s);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 101;
			background: rgba(0,0,0,.7);
			content: "";
		}
	}
}
a {
	color: $brand-primary;
	@include transition(.5s);
	&:hover, &:active, &:focus {
		color: $brand-primary;
		outline: none;
		text-decoration: none;
	}
}
p {
	margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6, figure {
	color: $brand-black;
	font-family: $font-primary;
	font-weight: 400;
	margin: 0 0 20px 0;
}
::-webkit-selection {
  color: $brand-white;
  background: $brand-primary;
}

::-moz-selection {
  color: $brand-white;
  background: $brand-primary;
}
::selection {
  color: $brand-white;
  background: $brand-primary;
}

.fh5co-nav {
	position: absolute;
	top: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	padding: 40px 0;
	z-index: 1001;
	@media screen and (max-width: $screen-sm) {
		padding: 20px 0;
	}
	#fh5co-logo {
		font-size: 40px;
		margin: 0;
		padding: 0;
		line-height: 40px;
		font-family: $font-secondary;
	}
	a {
		padding: 5px 10px;
		color: $brand-white;
	}
	.menu-1, .menu-2 {
		@media screen and (max-width: $screen-sm ) {
			display: none;
		}
	}
	ul {
		padding: 0;
		margin: 2px 0 0 0;
		li {
			padding: 0;
			margin: 0;
			list-style: none;
			display: inline;
			a {
				font-size: 14px;
				padding: 30px 10px;
				text-transform: uppercase;
				color: rgba(255,255,255,.5);
				@include transition(.5s);
				&:hover,&:focus, &:active {
					color: rgba(255,255,255,1);
				}
			}
			&.has-dropdown {
				position: relative;
				.dropdown {
					width: 130px;
					-webkit-box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.75);
					-moz-box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.75);
					box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.75);
					z-index: 1002;
					visibility: hidden;
					opacity: 0;
					position: absolute;
					top: 40px;
					left: 0;
					text-align: left;
					background: $brand-white;
					padding: 20px;
					@include border-radius(4px);
					@include transition(.0s);
					&:before {
						bottom: 100%;
						// left: 30%;
						left: 40px;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-bottom-color: #fff;
						border-width: 8px;
						margin-left: -8px;
					}
					
					li {
						display: block;
						margin-bottom: 7px;
						&:last-child {
							margin-bottom: 0;
						}
						a {
							padding: 2px 0;
							display: block;
							color: lighten($brand-black, 60%);
							line-height: 1.2;
							text-transform: none;
							font-size: 15px;
							&:hover {
								color: $brand-black;
							}
						}
					}
				}
				&:hover, &:focus {
					a {
						color: $brand-white;
					}
					.dropdown {
						// visibility: visible;
						// opacity: 1;
					}
				}
			}
			&.-cta {
				a {
					color: $brand-primary;
					span {
						background: $brand-white;
						padding: 4px 20px;
						@include inline-block;
						@include transition(.3s);
						@include border-radius(100px);
					}
					&:hover {
						span {
							-webkit-box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.75);
							-moz-box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.75);
							box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.75);
						}
					}
				}
			}
			&.active {
				> a {
					color: $brand-white!important;
				}
			}
		}
	}
}
// #fh5co-header,
// #fh5co-counter,
// #fh5co-event,
// .fh5co-bg {
// 	background-size: cover;
// 	background-position: top center;
// 	background-repeat: no-repeat;
// 	position: relative;
// }
#fh5co-counter,
#fh5co-event,
.fh5co-bg {
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	position: relative;
}
.fh5co-bg {
	background-position: center center;
	width: 100%;
	float: left;
	position: relative;
}

.fh5co-video {
	height: 450px;
	overflow: hidden;
	@include border-radius(7px);
	a {
		z-index: 1001;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -45px;
		margin-left: -45px;
		width: 90px;
		height: 90px;
		display: table;
		text-align: center;
		background: $brand-white;
		
		-webkit-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
		box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
		@include border-radius(50%);
		
		i {
			text-align: center;
			display: table-cell;
			vertical-align: middle;
			font-size: 40px;

		}
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .5);
		@include transition(.5s);
	}
	&:hover {
		.overlay {
			background: rgba(0, 0, 0, .7);		
		}
		a {
			position: relative;
			-webkit-transform: scale(1.2);
			-moz-transform: scale(1.2);
			-ms-transform: scale(1.2);
			-o-transform: scale(1.2);
			transform: scale(1.2);
			
		}
	}
}
.fh5co-cover {
	height: 900px;
	background-size: cover;
	// background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	width: 100%;
	.overlay {
		z-index:0;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, .5);
	}
	> .fh5co-container {
		position: relative;
		z-index: 10;
	}
	@media screen and (max-width: $screen-sm) {
		height: 600px;
	}
	.display-t,
	.display-tc {
		height: 900px;
		display: table;
		width: 100%;
		@media screen and (max-width: $screen-sm) {
			height: 600px;
		}
	}	

	&.fh5co-cover-sm {
		height: 600px;
		@media screen and (max-width: $screen-sm) {
			height: 400px;
		}
		.display-t,
		.display-tc {
			height: 600px;
			display: table;
			width: 100%;
			@media screen and (max-width: $screen-sm) {
				height: 400px;
			}
		}	
	}
}

#fh5co-counter,
#fh5co-event {
	height: 850px;
	float: left;
	.display-t,
	.display-tc {
		height: 700px;
		display: table;
		width: 100%;
	}
	.fh5co-heading{
		h2{
			color: $brand-white;
		}
		span{
			color: rgba($brand-white,.5);
			text-transform: uppercase;
			font-size: 13px;
			letter-spacing: 2px;
			font-weight: 600;
		}
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .4);
	}
	@media screen and (max-width: $screen-sm) {
		height: inherit;
		padding: 7em 0;
		.display-t,
		.display-tc {
			height: inherit;
		}
	}

	.event-wrap{
		border: 2px solid rgba($brand-white,.5);
		background:rgba($brand-white,.1);
		padding: 30px;
		width: 100%;
		float: left;
		@include border-radius(4px);
		@media screen and (max-width: $screen-sm) {
			margin-bottom: 10px;
		}
		h3{
			font-size: 20px;
			color: $brand-white;
			border-bottom: 1px solid rgba($brand-white,.5);
			display: block;
			padding-bottom: 20px;
			text-transform: uppercase;
			letter-spacing: 2px;
		}
		p,span{
			display: block;
			color: rgba($brand-white,.8);
		}
		i{
			color: rgba($brand-white,1);
			font-size: 20px;
		}
		.event-col{
			width: 50%;
			float: left;
			margin-bottom: 30px;
		}
	}

}


#fh5co-counter{
	height: 600px;
	@media screen and (max-width: $screen-sm) {
		height: auto;
	}
}


// TIMELINE
.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    &:before {
		top: 0;
		bottom: 0;
		position: absolute;
		content: " ";
		width: 1px;
		background-color: #d4d4d4;
		left: 50%;
		margin-left: 0px;
		@media screen and (max-width: $screen-xs) {
			margin-left: -64px;
		}
	}
	> li{
		margin-bottom: 20px;
		position: relative;
		&:before, &:after{
			content: " ";
			display: table;
		}
		&:after{
			clear: both;
		}
		> .timeline-panel {
			width: 40%;
			float: left;
			border: 1px solid #d4d4d4;
			padding: 30px;
			position: relative;
			@include border-radius(4px);
			&:before {
				position: absolute;
				top: 80px;
				right: -15px;
				display: inline-block;
				border-top: 15px solid transparent;
				border-left: 15px solid #ccc;
				border-right: 0 solid #ccc;
				border-bottom: 15px solid transparent;
				content: " ";
			}
			&:after {
				position: absolute;
				top: 81px;
				right: -14px;
				display: inline-block;
				border-top: 14px solid transparent;
				border-left: 14px solid #fff;
				border-right: 0 solid #fff;
				border-bottom: 14px solid transparent;
				content: " ";
			}
			@media screen and (max-width: $screen-xs) {
				width: 75% !important;
				&:before{
					top: 30px;
				}
				&:after{
					top: 31px;
				}
			}
		}
		> .timeline-badge {
			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;
			position: relative;
			color: #fff;
			width: 160px;
			height: 160px;
			line-height: 50px;
			font-size: 1.4em;
			text-align: center;
			position: absolute;
			top: 16px;
			left: 50%;
			margin-left: -80px;
			background-color: #999999;
			z-index: 100;
			@include border-radius(50%);
			@media screen and (max-width: $screen-xs) {
				width: 60px;
				height: 60px;
				margin-left: -20px !important;
			}
		}
		&.timeline-inverted {
			> .timeline-panel {
				float: right;
				&:before {
					border-left-width: 0;
					border-right-width: 15px;
					left: -15px;
					right: auto;
				}
				&:after {
					border-left-width: 0;
					border-right-width: 14px;
					left: -14px;
					right: auto;
				}
			}
		}
	}
}

.timeline-title {
    margin-top: 0;
}
.date{
	display: block;
	margin-bottom: 20px;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@media (max-width: $screen-md) {
	ul.timeline:before {
	  left: 90px;
	}

	ul.timeline > li > .timeline-panel {
	  width: calc(100% - 200px);
	  width: -moz-calc(100% - 200px);
	  width: -webkit-calc(100% - 200px);
	  // width: 40%;
	}

	ul.timeline > li > .timeline-badge {
	  left: 15px;
	  margin-left: 0;
	  top: 16px;
	}

	ul.timeline > li > .timeline-panel {
	  float: right;
	}

	ul.timeline > li > .timeline-panel:before {
	   border-left-width: 0;
	   border-right-width: 15px;
	   left: -15px;
	   right: auto;
	}

	ul.timeline > li > .timeline-panel:after {
	   border-left-width: 0;
	   border-right-width: 14px;
	   left: -14px;
	   right: auto;
	}
}


// GALLERY
#fh5co-gallery-list {
	@include flexwrap;
	@include flex;
	position: relative;
	float: left;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		display: block;
		padding: 0;
		margin: 0 0 10px 1%;
		list-style: none;
		min-height: 400px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		float: left;
		clear: left;
		position: relative;
		@include border-radius(7px);
		@media screen and (max-width: $screen-xs) {
			margin-left: 0;
		}
		a {
			min-height: 400px;
			padding: 2em;
			position: relative;
			width: 100%;
			display: block;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: "";
				z-index: 80;
				@include transition(.5s);
				@include border-radius(7px);

				background: rgba(0, 0, 0, .2);	

			}
			&:hover {
				&:before {
					background: rgba(0, 0, 0, .5);
				}
			}
			
			.case-studies-summary {
			   width: auto;
			   bottom: 2em;
			   left: 2em;
			   right: 2em;
			   position: absolute;
			   z-index: 100;
			   @media screen and (max-width: $screen-sm) {
			   	bottom: 1em;
				   left: 1em;
				   right: 1em;
			   }
			   span {
			   	text-transform: uppercase;
			   	letter-spacing: 2px;
			   	font-size: 13px;
			   	color: rgba(255,255,255,.7);
			   }
			   h2 {
			   	color: $brand-white;
			   	margin-bottom: 0;
			   	@media screen and (max-width: $screen-sm) {
			   		font-size: 20px;
			   	}
			   }

			}
		}
		&.one-third {
			width: 32.3%;
			@media screen and (max-width: $screen-sm) {
				width: 49%;	
			}
			@media screen and (max-width: $screen-xs) {
				width: 100%;	
			}
		}
		
	}
	
}


// Map
#map {
	width: 100%;
	height: 500px;
	position: relative;
	@media screen and (max-width: $screen-sm) {
		height: 200px;
	}
}

.fh5co-social-icons {
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
		list-style: none;
		@include inline-block;
		a {
			@include inline-block;
			color: $brand-primary;
			padding-left: 10px;
			padding-right: 10px;
			i {
				font-size: 20px;
			}
		}
	}
}

.fh5co-contact-info {
	ul {
		padding: 0;
		margin: 0;
		li {
			padding: 0 0 0 50px;
			margin: 0 0 30px 0;
			list-style: none;
			position: relative;
			&:before {
				color: $brand-primary;
				position: absolute;
				left: 0;
				top: .05em;
				@include icomoon;
			}
			&.address {
				&:before {
					font-size: 30px;	
					content: "\e9d1";
				}
			}
			&.phone {
				&:before {
					font-size: 23px;
					content: "\e9f4";
				}
			}
			&.email {
				&:before {
					font-size: 23px;
					content: "\e9da";
				}
			}
			&.url {
				&:before {
					font-size: 23px;
					content: "\e9af";
				}
			}
		}
	}
}


form {
	label {
		font-weight: normal!important;
	}
}


#fh5co-header,
#fh5co-counter,
.fh5co-cover {
	.display-tc {
		display: table-cell!important;
		vertical-align: middle;
		h1, h2 {
			margin: 0;
			padding: 0;
			color: rgba(255,255,255,1);
		}
		h1 {
			margin-bottom: 0px;
			font-size: 100px;
			line-height: 1.5;
			
			@media screen and (max-width: $screen-sm) {
				font-size: 40px;
			}
			@media screen and (max-width: $screen-xs) {
				font-size: 30px;
			}
		}
		h1.invitation-title{
			font-family: $font-secondary;
		}
		h2 {
			font-size: 20px;
			line-height: 1.5;
			margin-bottom: 30px;
			@media screen and (max-width: $screen-xs) {
				font-size: 16px;
			}
		}
		.btn {
			padding: 15px 20px;
			// background: $brand-white;
			// color: $brand-primary;
			border: none!important;
			font-size: 14px;
			text-transform: uppercase;
			// @include border-radius(2px);
			&:hover {
				background: $brand-white!important;
				color: $brand-primary;
				-webkit-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75)!important;
				-moz-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75)!important;
				box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75)!important;
			}
		}
		.btn-danger:hover {
			background-color: #d9534f !important;
			color: $brand-white!important;
		}
	}
}
#fh5co-counter {	
	text-align: center;
	.counter {
		font-size: 50px;
		margin-bottom: 10px;
		color: $brand-white;
		font-weight: 100;
		display: block;
	}
	.counter-label {
		margin-bottom: 0;
		text-transform: uppercase;
		color: rgba(255,255,255,.5);
		letter-spacing: .1em;
	}

	.feature-center {
		@media screen and (max-width: $screen-sm) {
			margin-bottom: 50px;	
		}
	}
	.icon {
		width: 70px;
		height: 70px;
		text-align: center;
		-webkit-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
		box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
		margin-bottom: 30px;
		i {
			height: 70px;
			&:before {
				display: block;
				text-align: center; 
				margin-left: 3px;
			}
		}
	}
}


// COUNTDOWN CUSTOM
.simply-countdown {
    /* The countdown */
    margin-bottom: 2em;
}
.simply-countdown > .simply-section {
    /* coutndown blocks */
    display: inline-block;
    width: 100px;
    height: 100px;
    background: rgba($brand-primary,.8);
    margin: 0 4px;
    @include border-radius(50%);
    position: relative;

    animation: pulse 1s ease infinite, 
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.simply-countdown > .simply-section > div {
    /* countdown block inner div */
    display: table-cell;
    vertical-align: middle;
    height: 100px;
    width: 100px;
}

.simply-countdown > .simply-section .simply-amount,
.simply-countdown > .simply-section .simply-word {
	display: block;
	color: rgba($brand-white,1);
    /* amounts and words */
}

.simply-countdown > .simply-section .simply-amount {
	font-size: 30px;
    /* amounts */
}

.simply-countdown > .simply-section .simply-word {
	color: rgba($brand-white,.7);
	text-transform: uppercase;
	font-size: 12px;
    /* words */
}

#fh5co-testimonial,
#fh5co-services,
#fh5co-started,
#fh5co-footer,
#fh5co-event,
#fh5co-couple-story,
#fh5co-couple,
#fh5co-gallery,
.fh5co-section {
	padding: 7em 0;
	clear: both;
	@media screen and (max-width: $screen-sm) {
		padding: 3em 0;
	}
}

.fh5co-section-gray{
	background: rgba($brand-black,.04);
}


// COUPLE
.couple-wrap{
	width: 90%;
	margin: 0 auto;
	position: relative;
	@media screen and (max-width: $screen-sm){
		width: 100%;
	}
}
.heart{
	position: absolute;
	top: 4em;
	left: 0;
	right: 0;
	z-index: 99;
	animation: pulse 1s ease infinite;
	i{
		font-size: 20px;
		background: $brand-white;
		padding: 20px;
		color: $brand-primary;
		@include border-radius(50%);
	}
	@media screen and (max-width: $screen-sm) {
		display: none;
	}
}
.couple-half{
	width: 50%;
	float: left;
	@media screen and (max-width: $screen-sm){
		width: 100%;
	}
	h3{
		font-family: $font-secondary;
		color: $brand-primary;
		font-size: 30px;
	}
	.groom,.bride{
		float: left;
		@include border-radius(50%);
		// overflow: hidden;
		// margin-left: 5px;
		width: 150px;
		height: 150px;
		img{
			width: 150px;
			height: 150px;
			@include border-radius(50%);
		}
		@media screen and (max-width: $screen-xs){
			width: 100%;
			height: 140px;
			img{
				width: 120px;
				height: 120px;
				margin: 0 auto;

			}
		}
	}
	.groom{
		float: right;
		margin-right: 5px;
	}
	.bride{
		float: left;
		margin-left: 5px;
	}
	.desc-groom{
		padding-right: 180px;
		text-align: right;
	}
	.desc-bride{
		padding-left: 180px;
		text-align: left;
	}
	@media screen and (max-width: $screen-xs){
		.groom,.bride{
			margin-left: 0;
			margin-right: 0;
		}
		.desc-groom{
			padding-right: 0;
			text-align: center;
		}
		.desc-bride{
			padding-left: 0;
			text-align: center;
		}
	}
}

.feature-center {
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
	float: left;
	width: 100%;
	margin-bottom: 40px;
	@media screen and (max-width: $screen-sm) {
		margin-bottom: 50px;		
	}
	
	.icon {
		width: 90px;
		height: 90px;
		background: #efefef;
		display: table;
		text-align: center;
		margin: 0 auto 30px auto;
		@include border-radius(50%);
		i {
			display: table-cell;
			vertical-align: middle;
			height: 90px;
			font-size: 40px;
			line-height: 40px;
			color: $brand-primary;
		}
		
	}
	p, h3 {
		margin-bottom: 30px;
	}
	h3 {
		text-transform: uppercase;
		font-size: 18px;
		color: #5d5d5d;
	}
}


.fh5co-heading {
	margin-bottom: 5em;
	&.fh5co-heading-sm {
		margin-bottom: 2em;
	}
	h2 {
		font-size: 60px;
		margin-bottom: 10px;
		line-height: 1.5;
		font-weight: bold;
		color: $brand-primary;
		font-family: $font-secondary;
		@media screen and (max-width: $screen-sm){
			font-size: 40px;
		}
	}
	p {
		font-size: 18px;
		line-height: 1.5;
		color: #828282;
	}
	span{
		text-transform: uppercase;
		font-size: 13px;
		letter-spacing: 2px;
		font-weight: 600;
		color: rgba($brand-black,.4);
	}
}

#fh5co-testimonial {
	.wrap-testimony{
		position: relative;
		.testimony-slide{
			text-align: center;
			span{
				font-size: 12px;
				text-transform: uppercase;
				letter-spacing: 2px;
				font-weight: 700;
				display: block;

				a.twitter{
					color: $brand-primary;
					font-weight: 300;
				}
			}

			figure{
				margin-bottom: 10px;
				@include inline-block;
				width: 100px;
				height: 100px;
				img {
					width: 100px;
					height: 100px;
					@include border-radius(50%);
				}
			}

			blockquote{
				border: none;
				margin: 30px auto;
				width: 50%;
				position: relative;


				@include border-radius(4px);

				@media screen and (max-width: $screen-md) {
					width: 100%;
				}

				p{
					font-style: italic;
					color: #8f989f;
					font-size: 20px;
					line-height: 1.6em;
				}
			}

			&.active{
				display: block;
			}
		}

		.arrow-thumb{
			position: absolute;
			top: 40%;
			display: block;
			width: 100%;

			a{
				font-size: 32px;
				color: #dadada;

				&:hover, &:focus, &:active{
					text-decoration: none;
				}
			}

		}
	}
}

.feature-left {
	float: left;
	width: 100%;
	margin-bottom: 30px;
	position: relative;
	&:last-child {
		margin-bottom: 0;
	}
	.icon {
		float: left;
		margin-right: 5%;
		width: 54px;
		height: 54px;	
		background: $brand-white;
		display: table;
		text-align: center;
		-webkit-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);
		box-shadow: 0px 14px 30px -15px rgba(0,0,0,0.75);

		@include border-radius(50%);
		@media screen and (max-width: $screen-sm) {
			margin-right: 5%;
		}
		i {
			display: table-cell;
			vertical-align: middle;
			font-size: 30px;
			color: $brand-primary;
		}
	}
	.feature-copy {
		float: left;
		width: 80%;
		@media screen and (max-width: $screen-sm) {
			width: 70%;
			float: left;
		}
		h3 {
			text-transform: uppercase;
			font-size: 18px;
			color: #5d5d5d;
			margin-bottom: 10px;
		}
		
	}
}

#fh5co-started {
	position: relative;
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .4);
	}
	.fh5co-heading {
		h2 {
			color: $brand-white;
		}
		p{
			color: rgba($brand-white,.5);
		}
	}

	.form-control {
		background: rgba(255,255,255,.2);
		border: none!important;
		color: $brand-white;
		font-size: 16px!important;
		width: 100%;


		&::-webkit-input-placeholder {
		   color: $brand-white;
		}

		&:-moz-placeholder { /* Firefox 18- */
		   color: $brand-white;  
		}

		&::-moz-placeholder {  /* Firefox 19+ */
		   color: $brand-white;  
		}

		&:-ms-input-placeholder {  
		   color: $brand-white;  
		}
		@include transition(.5s);
		&:focus {
			background: rgba(255,255,255,.3);
		}

	}
	.btn {
		height: 54px;
		border: none!important;
		background: $brand-primary;
		color: $brand-white;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 400;
		padding-left: 50px;
		padding-right: 50px;
	}
	.form-inline {
		.form-group {
			width: 100%!important;
			margin-bottom: 10px;
			.form-control {
				width: 100%;
			}
		}
	}
	.fh5co-heading {
		margin-bottom: 30px;
		h2 {
			margin-bottom: 0;
		}
	}
}

#fh5co-footer {

	.copyright {
		.pull-left,
		.pull-right {
			@media screen and (max-width: $screen-sm) {
				float: none!important;
				// margin-bottom: 0;
				text-align: center;
			}
		}
		.block {
			display: block;
		}
	}
}

// Owl 
.owl-carousel .owl-controls .owl-dot {
	span {
		background: lighten($brand-black, 90%);
		&:hover, &:focus {
			background: lighten($brand-black, 80%);
		}
	}
	&:hover, &:focus {
		span {
			background: lighten($brand-black, 80%);
		}
	}
	&.active {
		span {
			background: transparent;
			border: 2px solid $brand-primary;
		}
	}
}

// off canvas
#fh5co-offcanvas {
	position: absolute;
	z-index: 1901;
	width: 270px;
	background: lighten($brand-black, 0%);
	top: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 45px 40px 40px 40px;
	overflow-y: auto;
	@include translateX(270px);
	@include transition(.5s);
	.offcanvas & {
		@include translateX(0px);
	}
	a {
		color: rgba(255,255,255,.5);
		&:hover {
			color: rgba(255,255,255,.8);
		}
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			padding: 0;
			margin: 0;
			list-style: none;
			> ul {
				padding-left: 20px;
				display: none;
			}
			&.offcanvas-has-dropdown {
				> a {
					display: block;
					position: relative;
					&:after {
						position: absolute;
						right: 0px;
						@include icomoon;
						content: "\e921";
						font-size: 20px;
						color: rgba(255,255,255,.2);
						@include transition(.5s);
					}
				}
				&.active {
					a {
						&:after {
							-webkit-transform: rotate(-180deg);
							-moz-transform: rotate(-180deg);
							-ms-transform: rotate(-180deg);
							-o-transform: rotate(-180deg);
							transform: rotate(-180deg);
						}
					}
				}
			}
		}
	}
}

.uppercase {
	font-size: 14px;
	color: $brand-black;
	margin-bottom: 10px;
	font-weight: 700;
	text-transform: uppercase;
}
.gototop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	@include transition(.5s);
	&.active {
		opacity: 1;
		visibility: visible;
	}
	a {
		width: 50px;
		height: 50px;
		display: table;
		background: rgba(0,0,0,.5);
		color: $brand-white;
		text-align: center;
		@include border-radius(4px);
		i {
			height: 50px;
			display: table-cell;
			vertical-align: middle;

		}
		&:hover, &:active, &:focus {
			text-decoration: none;
			outline: none;
		}
	}	
}



// Burger Menu
.fh5co-nav-toggle {
  width:25px;
  height:25px;
  cursor: pointer;
  text-decoration: none;
  &.active i {
		&::before, &::after {
			background: $brand-darker;
		}
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  display: inline-block;
	  width: 25px;
	  height: 2px;
	  color: #252525;
	  font:bold 14px/.4 Helvetica;
	  text-transform: uppercase;
	  text-indent:-55px;
	  background: #252525;
	  transition: all .2s ease-out;
		 &::before, &::after {
	  	content:'';
		  width: 25px;
		  height: 2px;
		  background: #252525;
		  position: absolute;
		  left:0;
		  transition:all .2s ease-out;
	  }
  }
  &.fh5co-nav-white {
  	> i {
  		color:$brand-white;
  		background: $brand-white;
  		&::before, &::after {
  			background: $brand-white;
  		}
  	}
  }
}

.fh5co-nav-toggle i::before {
  top: -7px;
}
.fh5co-nav-toggle i::after {
  bottom: -7px;
}
.fh5co-nav-toggle:hover i::before {
  top: -10px;
}
.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}
.fh5co-nav-toggle.active i {
	background: transparent;
}
.fh5co-nav-toggle.active i::before {
  top:0;
  -webkit-transform: rotateZ(45deg);
     -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
       -o-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.fh5co-nav-toggle.active i::after {
  bottom:0;
  -webkit-transform: rotateZ(-45deg);
     -moz-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
       -o-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
}
.fh5co-nav-toggle {
  position: absolute;
  right: 0px;
  top: 10px;
  // top: 100px;
  // left: -100px;;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  // background: #f86942;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none!important;
  @media screen and (max-width: $screen-sm) {
  	display: block;
  }
}

// Buttons
.btn {
	margin-right: 4px;
	margin-bottom: 4px;
	font-family: $font-primary;
	font-size: 16px;
	font-weight: 400;
	@include border-radius(30px);
	@include transition(.5s);
	padding: 8px 20px;
	&.btn-md {
		padding: 8px 20px!important;
	}
	&.btn-lg {
		padding: 18px 36px!important;
	}
	&:hover, &:active, &:focus {
		box-shadow: none!important;
		outline: none!important;
	}
}
.btn-primary {
	background: $brand-primary;
	color: $brand-white;
	border: 2px solid $brand-primary;
	&:hover, &:focus, &:active {
		background: lighten($brand-primary, 5%)!important;
		border-color: lighten($brand-primary, 5%)!important;
	}
	&:disabled {
		background: lighten($brand-primary, 10%)!important;
		border-color: #F14E95
	}
	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		border: 2px solid $brand-primary;
		&:hover, &:focus, &:active {
			background: $brand-primary;
			color: $brand-white;
		}
	}
}
.btn-success {
	background: $brand-success;
	color: $brand-white;
	border: 2px solid $brand-success;
	&:hover, &:focus, &:active {
		background: darken($brand-success, 5%)!important;
		border-color: darken($brand-success, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-success;
		border: 2px solid $brand-success;
		&:hover, &:focus, &:active {
			background: $brand-success;
			color: $brand-white;
		}
	}
}
.btn-info {
	background: $brand-info;
	color: $brand-white;
	border: 2px solid $brand-info;
	&:hover, &:focus, &:active {
		background: darken($brand-info, 5%)!important;
		border-color: darken($brand-info, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-info;
		border: 2px solid $brand-info;
		&:hover, &:focus, &:active {
			background: $brand-info;
			color: $brand-white;
		}
	}
}
.btn-warning {
	background: $brand-warning;
	color: $brand-white;
	border: 2px solid $brand-warning;
	&:hover, &:focus, &:active {
		background: darken($brand-warning, 5%)!important;
		border-color: darken($brand-warning, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-warning;
		border: 2px solid $brand-warning;
		&:hover, &:focus, &:active {
			background: $brand-warning;
			color: $brand-white;
		}
	}
}
.btn-danger {
	background: $brand-danger;
	color: $brand-white;
	border: 2px solid $brand-danger;
	&:hover, &:focus, &:active {
		background: darken($brand-danger, 5%)!important;
		border-color: darken($brand-danger, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-danger;
		border: 2px solid $brand-danger;
		&:hover, &:focus, &:active {
			background: $brand-danger;
			color: $brand-white;
		}
	}
}

.btn-outline {
	background: none;
	border: 2px solid lighten($brand-black, 50%);
	font-size: 16px;
	@include transition(.3s);
	&:hover, &:focus, &:active {
		box-shadow: none;
	}
}

.btn.with-arrow {
	position: relative;
	@include transition(.3s);
	i {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		right: 0px;
		top: 50%;
		margin-top: -8px;
		@include transition(.2s);
	}
	&:hover {
		padding-right: 50px;
		i {
			color: $brand-white;
			right: 18px;
			visibility: visible;
			opacity: 1;
		}
	}
}
// Form Input Field
.form-control {
	box-shadow: none;
	background: transparent;
	border: 2px solid rgba(0, 0, 0, 0.1);
	height: 54px;
	font-size: 18px;
	font-weight: 300;
  	&:active, &:focus {
  		outline: none;
		box-shadow: none;
		border-color: $brand-primary;
  }
}

.row-pb-md {
	padding-bottom: 4em!important;
}
.row-pb-sm {
	padding-bottom: 2em!important;
}

.fh5co-loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url(../images/loader.gif) center no-repeat #fff;
}

.animate-box {
	.js & {
		opacity: 0;
	}
}


//Loading button
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
      transform: rotate(0turn);
  }

  to {
      transform: rotate(1turn);
  }
}





.wedding-snack-bar {
	background-color: #F14E95;
	color: white;
	text-align: center;

	.mat-simple-snackbar {
		justify-content: center;
	}
}

.danger-snack-bar {
	background-color: #d9534f;
	color: white;
}

// GALLERY
#fh5co-gift-list {
	@include flexwrap;
	@include flex;
	position: relative;
	float: left;
	justify-content: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		cursor: pointer;
		display: block;
		padding: 0;
		margin: 0 0 10px 1%;
		list-style: none;
		// min-height: 200px;
		background-color: #F14E95;
		float: left;
		clear: left;
		position: relative;
		@include border-radius(7px);
		@media screen and (max-width: $screen-xs) {
			margin-left: 0;
		}
		a {
			// min-height: 400px;
			padding: 2em;
			position: relative;
			width: 100%;
			display: block;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: "";
				z-index: 80;
				@include transition(.5s);
				@include border-radius(7px);


			}
			&:hover {
				&:before {
					background: rgba(0, 0, 0, .5);
				}
			}

			.gift-content {
				display:flex;
				justify-content: space-between;
				align-items: center;

				.remove-gift {
					color: white;
					font-size: 3em;
					line-height: 1em;
				}

				.gift-infos {
					width: auto;
					bottom: 2em;
					left: 2em;
					right: 2em;
						//    position: absolute;
					z-index: 100;
					@media screen and (max-width: $screen-sm) {
						bottom: 1em;
						left: 1em;
						right: 1em;
					}
					span {
						text-transform: uppercase;
						letter-spacing: 2px;
						font-size: 13px;
						color: $brand-white;
					}
					h2 {
						color: $brand-white;
						margin-bottom: 0;
						@media screen and (max-width: $screen-sm) {
							font-size: 20px;
						}
					}
				}
			}
		}
		&.one-third {
			width: 32.3%;
			@media screen and (max-width: $screen-sm) {
				width: 49%;	
			}
			@media screen and (max-width: $screen-xs) {
				width: 100%;	
			}
		}
		
	}
	
}